import { Fragment, useEffect, useState } from 'react';

import { GLOBAL } from 'saddlebag-browser';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';
import {
  BpkTableRow,
  BpkTableCell,
  BpkTableHeadCell,
} from '@skyscanner/backpack-web/bpk-component-table';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { anchorScroll } from '../../utils';
import getIcon from '../common/Icon';
import { useMBDContext, buildMBDParam } from '../common/MBD/useMBDContext';

import { logMiniEventForClick, logMinEventForLoading } from './miniEvent';

import type { SnippetProps } from '@skyscanner-internal/falcon-shared-types/types/InfoSnippetProps';

import STYLES from './Snippet.module.scss';

const CONTENT_TYPE = {
  BUTTON: 'button',
  LINK: 'link',
  LIST: 'list',
  TEXT: 'text',
  HTML: 'html',
};
const $document = GLOBAL.getDocument();

const Snippet = ({
  ariaText,
  icon,
  id,
  isHtml,
  isMobile = false,
  label,
  linkData,
  linkUrl,
  scrollId,
  snippetIndex,
  titleData = {},
  value,
}: SnippetProps) => {
  const BpkIcon = getIcon(icon);
  const { rawComponentName, vertical } = useMBDContext();
  const MBD_COMPONENT_NAME = `${rawComponentName}_item`;

  let type;
  if (linkData) {
    type = CONTENT_TYPE.LIST;
  } else if (isHtml) {
    type = CONTENT_TYPE.HTML;
  } else if (linkUrl) {
    type = CONTENT_TYPE.LINK;
  } else {
    type = CONTENT_TYPE.TEXT;
  }

  const [contentType, setContentType] = useState(type);

  let valueNode;
  if (Array.isArray(value)) {
    // If the `value` prop is an array, we want to show each string element on a new line
    // We do this by adding a <li> as a list
    const listNode = (value as string[]).flatMap(
      (line: string | number, index: number) => (
        <li key={`${line}-li`}>{line}</li>
      ),
    );
    valueNode = <ul className="value">{listNode}</ul>;
  }
  const handleClick = (
    url?: string,
    val?: string | string[] | number | null,
    html?: boolean,
  ) => {
    let valString = val;
    if (html && typeof val === 'string') {
      // Converts a string to an HTML element
      const parser = new DOMParser();
      const doc = parser.parseFromString(val, 'text/html');

      valString = doc.body.textContent || '';
    }
    logMiniEventForClick(url, valString);
  };

  const onScrollClick = () => {
    logMiniEventForClick(scrollId, value);
    anchorScroll(scrollId);
  };

  useEffect(() => {
    if (scrollId && !!$document.getElementById(scrollId)) {
      setContentType(CONTENT_TYPE.BUTTON);
    }
  }, [scrollId]);

  logMinEventForLoading();

  let cellContent;
  if (contentType === CONTENT_TYPE.LIST) {
    cellContent = linkData?.map((linkItem: any, index: number) => (
      <Fragment key={linkItem.airportName}>
        {index > 0 && ', '}
        {linkItem.link ? (
          <BpkLink
            data-tracking-element-id={`${MBD_COMPONENT_NAME}_${snippetIndex}_${index}`}
            data-tracking-common-params={buildMBDParam(
              MBD_COMPONENT_NAME,
              vertical,
            )}
            data-testid="linkurl"
            href={linkItem.link}
            aria-label={ariaText}
            onClick={() => handleClick(linkItem.link, linkItem.airportName)}
            className={STYLES.Snippet__clickAndValueLink}
            {...titleData}
          >
            {linkItem.airportName}
          </BpkLink>
        ) : (
          <BpkText
            data-testid="subtext"
            textStyle={isMobile ? TEXT_STYLES.heading5 : TEXT_STYLES.heading4}
            className={STYLES.Snippet__clickAndValue}
            {...titleData}
          >
            {linkItem.airportName}
          </BpkText>
        )}
      </Fragment>
    ));
  } else if (contentType === CONTENT_TYPE.BUTTON) {
    cellContent = (
      <BpkButton
        link
        aria-label={ariaText}
        className={STYLES.Snippet__clickAndValue}
        onClick={() => onScrollClick()}
        data-tracking-element-id={`${MBD_COMPONENT_NAME}_${snippetIndex}`}
        data-tracking-common-params={buildMBDParam(
          MBD_COMPONENT_NAME,
          vertical,
        )}
      >
        <span className={STYLES.Snippet__valueText}>{valueNode || value}</span>
      </BpkButton>
    );
  } else if (contentType === CONTENT_TYPE.LINK) {
    cellContent = (
      <BpkLink
        data-testid="linkurl"
        href={linkUrl}
        rel="nofollow"
        aria-label={ariaText}
        onClick={() => handleClick(linkUrl, value)}
        className={STYLES.Snippet__clickAndValueLink}
        {...titleData}
        data-tracking-element-id={`${MBD_COMPONENT_NAME}_${snippetIndex}`}
        data-tracking-common-params={buildMBDParam(
          MBD_COMPONENT_NAME,
          vertical,
        )}
      >
        {valueNode || value}
      </BpkLink>
    );
  } else if (contentType === CONTENT_TYPE.HTML) {
    cellContent = (
      <BpkText
        data-tracking-element-id={`${MBD_COMPONENT_NAME}_${snippetIndex}`}
        data-tracking-common-params={buildMBDParam(
          MBD_COMPONENT_NAME,
          vertical,
        )}
        data-testid="subtext"
        textStyle={isMobile ? TEXT_STYLES.heading5 : TEXT_STYLES.heading4}
        className={STYLES.Snippet__clickAndValue}
        {...titleData}
        onClick={() => handleClick(linkUrl, value, isHtml)}
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  } else if (contentType === CONTENT_TYPE.TEXT) {
    cellContent = (
      <BpkText
        data-testid="subtext"
        textStyle={isMobile ? TEXT_STYLES.heading5 : TEXT_STYLES.heading4}
        className={STYLES.Snippet__clickAndValue}
        {...titleData}
      >
        {valueNode || value}
      </BpkText>
    );
  }

  return (
    <BpkTableRow className={STYLES.Snippet} data-testid={id}>
      <BpkTableHeadCell className={STYLES.Snippet__row}>
        <BpkIcon className={STYLES.Snippet__icon} />
        <BpkText
          data-testid="snippet-header"
          className={STYLES.label}
          textStyle={TEXT_STYLES.bodyDefault}
        >
          {label}
        </BpkText>
      </BpkTableHeadCell>
      <BpkTableCell className={STYLES.Snippet__row}>{cellContent}</BpkTableCell>
    </BpkTableRow>
  );
};

export default Snippet;
